import React from 'react'
import {Link} from 'gatsby'
import styled from 'styled-components'
import {colors, font, fontfamily} from '../lib/variables'

const getWaveHeight = (width) => ~~(width / 375 * 57) + 'px'

const Wrapper = styled.footer`
  display: block;
  position: relative;
  background: #29384A;
  color: ${colors.white};
  padding: 4rem 1.6rem 4rem 1.6rem;
  @media (min-width: 1024px) {
    padding-top: 8rem;
    margin-top: 8rem;
  }

`

const Inner = styled.div`
  display: block;
  max-width: 1024px;
  margin: 0 auto;
`

const Wave = styled.div`
  background: url('/footerBg.svg');
  background-repeat: no-repeat;
  background-size: 100%;
  width: 100%;
  height: ${({width}) => getWaveHeight(width)};
  @media (min-width: 1024px) {
    display: none;
  }
`
const Title = styled.span`
  font-family: ${fontfamily.jaRounded};
  display: block;
  font-size: 1.8rem;
`
const SubTitle = styled.span`
  font-family: ${fontfamily.jaRounded};
  display: block;
  font-size: 1.0rem;
`

const Header = styled.div`
  display: block;
  margin: 0 0 2.4rem 0;
`

const Nav = styled.nav`
  display: block;
`

const List = styled.ul`
  display: block;
  font-family: ${fontfamily.jaRounded};
  font-size: ${props => !props.small ? '1.6rem' : '1.4rem'};
  font-weight: ${props => !props.small ? '700' : '400'};
  margin-bottom:  ${props => !props.small ? '4.0rem' : 'none'};
  min-height: 200px;
`

const Item = styled.li`
  margin: 0 0 2.0rem 0;
`

const StyledLink = styled(props => <Link {...props} />)`
  color: white;
  text-decoration: none;
`

const ExternalLink = styled.a`
  color: white;
  text-decoration: none;
`

const Copyright = styled.span`
  opacity: 0.7;
  padding-top: 2.4rem;
  display: block;
`

class Footer extends React.Component {
  constructor (props) {
    super(props)
    this.state = {windowWidth: 0}
  }

  handleWindowSizeChange = () => {
    this.setState({ windowWidth: window.innerWidth })
  }

  componentDidMount () {
    this.handleWindowSizeChange()
    window.addEventListener('resize', this.handleWindowSizeChange)
  }
  componentWillUnmount () {
    window.removeEventListener('resize', this.handleWindowSizeChange)
  }

  render () {
    return (
      <>
        <Wave width={this.state.windowWidth} />
        <Wrapper>
          <Inner>
            {/* <Header>
              <Title>ニューヨーク育英学園</Title>
              <SubTitle>米国非営利学校法人</SubTitle>
            </Header> */}
            <Nav>
              {/* <List>
                <Item><StyledLink to='/summer-2020/'>2020年サマーキャンプ</StyledLink></Item>
              </List> */}
              <List>
              <Item><StyledLink to='/terms/summer-2020'>オンラインサマーキャンプ2020第一ターム参加規約</StyledLink></Item>
              <Item><StyledLink to='/terms/summer-2020-term2'>オンラインサマーキャンプ2020第二ターム参加規約</StyledLink></Item>
                <Item><ExternalLink href='https://www.nyikueionlinesummer.com/' target='_blank'>サマースクール&キャンプ2020ウェブサイト</ExternalLink></Item>
                <Item><ExternalLink href='https://japaneseschool.org/' target='_blank'>ニューヨーク育英学園ウェブサイト</ExternalLink></Item>
              </List>
            </Nav>
            <Copyright>&copy; Japanese Children's Society, Inc.</Copyright>
          </Inner>
        </Wrapper>
      </>
    )
  }
}
export default Footer
