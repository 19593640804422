export const mainWidth = '1024px'

export const spacing = {
  mobile: {
    wrapper: '16px'
  },
  desktop: {
    wrapper: '32px'
  }
}

export const colors = {
  black: '#01090C',
  lightBlack: '#373B40',
  white: '#ffffff',
  darkGray: '#E5E5E5',
  gray: '#F6F6F6',
  red: '#E61421',
  green: '#18BA61',
  blue: '#026EFB',
  yellow: '#FADB55',
  navy: '#29384A',
  lightNavy: '#8591A0'
}

export const fontfamily = {
  ja: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', 'Hiragino Sans', 'Hiragino Kaku Gothic ProN', 'メイリオ', meiryo, sans-serif",
  jaRounded: "'M PLUS Rounded 1c', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', 'Hiragino Sans', 'Hiragino Kaku Gothic ProN', 'メイリオ', meiryo, sans-serif"
}

export const font = {
  desktop: {
    base: {
      size: '1.6rem',
      weight: '300',
      lineHeight: '2.6rem',
      letterSpacing: '0.15rem'
    },
    huge: {
      size: '4.0rem',
      weight: '300',
      lineHeight: '1',
      letterSpacing: '1'
    },
    xlarge: {
      size: '3.0rem',
      weight: '300',
      lineHeight: '1',
      letterSpacing: '1'
    },
    large: {
      size: '2.6rem',
      weight: '300',
      lineHeight: '1',
      letterSpacing: '1'
    },
    medium: {
      size: '1.8rem',
      weight: '400',
      lineHeight: '2.4rem',
      letterSpacing: '0.15rem'
    },
    small: {
      size: '1.4rem',
      weight: '400',
      lineHeight: '2.4rem',
      letterSpacing: '0.15rem'
    },
    xsmall: {
      size: '1.2rem',
      weight: '400',
      lineHeight: '2.4rem',
      letterSpacing: '0.15rem'
    },
    tiny: {
      size: '0.8rem',
      weight: '400',
      lineHeight: '2.4rem',
      letterSpacing: '0.15rem'
    }
  },
  mobile: {
    base: {
      size: '1.4rem',
      weight: 'normal',
      lineHeight: '2.4rem',
      letterSpacing: '0.15rem'
    },
    huge: {
      size: '3.0rem',
      weight: '300',
      lineHeight: '1',
      letterSpacing: '1'
    },
    xlarge: {
      size: '3.0rem',
      weight: '300',
      lineHeight: '1',
      letterSpacing: '1'
    },
    large: {
      size: '2.6rem',
      weight: '300',
      lineHeight: '1',
      letterSpacing: '1'
    },
    medium: {
      size: '1.8rem',
      weight: '400',
      lineHeight: '2.4rem',
      letterSpacing: '0.15rem'
    },
    small: {
      size: '1.4rem',
      weight: '400',
      lineHeight: '2.4rem',
      letterSpacing: '0.15rem'
    },
    xsmall: {
      size: '1.2rem',
      weight: '400',
      lineHeight: '2.4rem',
      letterSpacing: '0.15rem'
    },
    tiny: {
      size: '0.8rem',
      weight: '400',
      lineHeight: '2.4rem',
      letterSpacing: '0.15rem'
    }
  }
}
