import React from 'react'
import {Link} from 'gatsby'

import styled from 'styled-components'
import {colors, font, fontfamily} from '../lib/variables'

const Wrapper = styled.header`
  width: 100%;
  height: 52px;
  background: ${colors.navy};
  border-radius: 0 0 12px 12px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.09);
  z-index: 100;
  /* position: fixed; */
  top: 0;
  left: 0;
  border-bottom: 2px solid #e0e0e0;
  @media (min-width: 768px) {
    height: 80px;
  }
`

const Inner = styled.div`
  max-width: 1024px;
  height: 52px;
  margin: 0 auto;
  display: block;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (min-width: 768px) {
    height: 80px;
  }
`

const Title = styled(props => <Link {...props} />)`
  font-family: ${fontfamily.jaRounded};
  font-weight: 700;
  font-size: 1.6rem;
  display: block;
  margin: 0 0 0 1.6rem;
  color: #fafafa;
  text-decoration: none;
  @media (min-width: 768px) {
    font-size: 2.0rem;
  }

`

const SubTitle = styled.span`
  display: block;
  color: #fafafa;
  font-size: 10px;
  margin: -0.2rem 0 0 1.6rem;
  @media (min-width: 768px) {
    font-size: 1.1rem;
  }

`

const Admission = styled(props => <Link {...props} />)`
  font-family: ${fontfamily.ja};
  font-size: 14px;
  display: block;
  color: ${colors.navy};
  margin: 0 1.8rem 0 0;
  text-decoration: none;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const NavIcon = styled.button`
  width: 48px;
  height: 48px;
  position: relative;
  cursor: pointer;
  background: none;
  border: none;
  &:before {
    transform: rotate(0deg);
    top: 19px;
    left: 12px;
    content: '';
    width: 24px;
    height: 1px;
    position: absolute;
    background: ${colors.black};
    transition: all 0.25s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    border-radius: 4px;
    }
  &.close:before {
    transform: rotate(135deg);
    top: 24px;
  }
  &:after{
    transform: rotate(0deg);
    bottom: 19px;
    left: 12px;
    content: '';
    width: 24px;
    height: 1px;
    position: absolute;
    background: ${colors.black};
    transition: all 0.25s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    border-radius: 4px;
  }
  &.close:after{
    transform: rotate(45deg);
    bottom: 23px;
  }
  @media (min-width: 1024px) {
    display: none;
  }
`

const List = styled.ul`
  display: none;
  @media (min-width: 1024px) {
    font-family: ${fontfamily.jaRounded};
    font-size: ${props => !props.small ? '1.6rem' : '1.4rem'};
    font-weight: ${props => !props.small ? '700' : '400'};
    align-items: space-between;
    justify-content: center;
    display: flex;
  }

`

const Item = styled.li`
  margin: 0 0 0 2.8rem;
`

const StyledLink = styled(props => <Link {...props} />)`
  color: #fafafa;
  text-decoration: none;
`
const ExternalLink = styled.a`
  color: #fafafa;
  text-decoration: none;
  font-size: 1.2rem;
`

const Header = ({onHideNav, onShowNav, showNav, siteTitle, subTitle, headerLink}) => (
  <>
    <Wrapper>
      <Inner>
        <div>
          <Title to='/'>
            ニューヨーク育英学園 - 申込・決済サイト
          </Title>
          <SubTitle>
            学校非営利法人
          </SubTitle>
        </div>
        {/* <Row>
          <NavIcon onClick={showNav ? onHideNav : onShowNav} className={showNav && 'close'} />
        </Row> */}
        <List>
          {headerLink !== 'official' && <Item><ExternalLink href='https://www.nyikueionlinesummer.com/' target='_blank'>サマースクール&キャンプ2020ウェブサイト</ExternalLink></Item>}
          {headerLink === 'official' && <Item><ExternalLink href='https://japaneseschool.org/' target='_blank'>ニューヨーク育英学園ウェブサイト</ExternalLink></Item>}

        </List>
      </Inner>
    </Wrapper>
  </>
)

export default Header
