import React from 'react'
import Helmet from 'react-helmet'

function SEO ({description, lang, meta, keywords, title, image, robots}) {
  return (
    <Helmet
      htmlAttributes={{lang}}
      title={title}
      meta={[
        {
          name: 'robots',
          content: 'none'
        }
      ]}
    />
  )
}

export default SEO
